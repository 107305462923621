import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Report } from '../../models/makeReport.model';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
import SanitizedHTMLContainer from '../SanitizedHTMLContainer/SanitizedHTMLContainer';
import VideoWrapper from '../VideoWrapper/VideoWrapper';
import './SingleReportContent.scss';

type SingleReportContentProps = {
  report: Report
}

const SingleReportContent: React.FC<SingleReportContentProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get videoLink() {
      return p.report.$.video_path ?? "";
    },
    get canAccess() {
      return p.report.$.is_accessible;
    },
  }))

  return <Observer children={() => (
    <div className="SingleReportContent">
      <div className="AnimateEntry SingleReportContainer">
        {/* <VideoWrapper id="ReportVideo" src={s.videoLink} autoPlay loop controls /> */}
        {s.videoLink! ? <VideoWrapper id="ReportVideo" src={s.videoLink} autoPlay loop controls /> : s.videoLink && <div className="FallbackVideo"><p>Cannot access video with current plan.</p></div>}
        {/* {s.canAccess ? <VideoWrapper id="ReportVideo" src={s.videoLink} autoPlay loop controls /> : s.videoLink && <div className="FallbackVideo"><p>Cannot access video with current plan.</p></div>} */}
        {s.videoLink! && <BaseSpacer size="md" />}
        {props.report.$.intro_description! && <SanitizedHTMLContainer source={props.report.$.intro_description} /> }
        <BaseSpacer size="md" />
        <SanitizedHTMLContainer source={props.report.$.description} />
      </div>
    </div>
  )} />
}

export default SingleReportContent;