import { Observer } from 'mobx-react-lite';
import React from 'react';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import WindowTitle from '../../components/WindowTitle/WindowTitle';
import { useRootContext } from '../../controllers/root/root.controller';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { runAfter } from '../../utils/delays.utils';
import './PageLogout.scss';

type PageLogoutProps = {}

const PageLogout: React.FC<PageLogoutProps> = props => {
  const { AUTH, NAVIGATOR } = useRootContext();
  useOnMount(() => {
    runAfter(() => {
      AUTH.logout();
      NAVIGATOR.redirectTo('/login');
    }, 1500);
  })
  return <Observer children={() => (
    <div className="PageLogout">
      <WindowTitle title="Logging out..." />
      <p>Logging out<LoadingIndicator /></p>
    </div>
  )} />
}

export default PageLogout;