import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useReportIndexContext } from '../../controllers/reportIndex.controller';
import { useRootContext } from '../../controllers/root/root.controller';
import { Product } from '../../models/makeProduct.model';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseCheckbox from '../BaseCheckbox/BaseCheckbox';
import './ProductCheckboxList.scss';

interface ProductCheckboxListProps {
  products?: Product[],
  searchQuery?: string,
}

const ProductCheckboxList = (props: React.PropsWithChildren<ProductCheckboxListProps>) => {

  const { LOCALDB } = useRootContext();

  const p = useProps(props);

  const reportIndex = useReportIndexContext();

  const s = useStore(() => ({
    get products() {
      return p.products ?? LOCALDB.data.products;
    },
    label: (product: Product) => {
      return `${product.$.name}`
    },
    matchesSearch: (product: Product) => {
      return s.label(product).toLowerCase().includes(p.searchQuery?.toLowerCase() ?? "") ?? false
    },
  }));

  return <Observer children={() => (
    <ul className="CheckboxList ProductCheckboxList">
      {s.products.map(product =>
        (!p.searchQuery || s.matchesSearch(product)) ?
          <li className="AnimateEntry" key={product.$.id}>
            <BaseCheckbox
              getter={() => reportIndex.productFilterIds.includes(product.$.id!)}
              setter={action(() => reportIndex.updateProductFilter(product))}
              Label={<>{s.label(product)}</>}
            />
          </li> :
          ""
      )}
    </ul>
  )} />
}

export default ProductCheckboxList;