import { AxiosResponse } from "axios";
import { action, observable } from "mobx";
import { AXIOS } from "../../utils/axios.utils";
import { isArray, isObject } from "../../utils/typeChecks.utils";
import { TOKEN_STORAGE_KEY } from "./auth.controller";
import { ModelTypeName } from "./localDB.controller";
import { RootController } from "./root.controller";

export const makeHeaders = () => {
  const token = localStorage.getItem(TOKEN_STORAGE_KEY);
  const baseHeaderPartial = {
    'Accept': 'application/json',
    'common': { 'X-Requested-With': 'XMLHttpRequest',
    }
  };
  const headers = token ? {
    'Authorization': `Bearer ${token}`,
    ...baseHeaderPartial,
  } : baseHeaderPartial
  return headers;
}

export const makeAPIController = () => {

  const s = observable({
    ROOT: null as RootController | null,
    getOne: async <T>(
      url: string,
      modelTypeName: ModelTypeName,
    ) => {
      const headers = makeHeaders();
      const response = await AXIOS.get(url, { headers });
      // laravel get-one request returns the model object directly in response.data
      if (isObject(response.data)) return s.ROOT?.LOCALDB.addOne<T>(modelTypeName, response.data);
      // if the data shape is not an object, we don't know how to respond. return the response directly.
      console.warn(`Expected request to ${url} to return an object, but received type of [${typeof response.data}]. The response data has been returned directly.`);
      console.log(response.data);
      return response.data;
    },
    getMany: async <T>(
      url: string,
      modelTypeName: ModelTypeName,
    ) => {
      const headers = makeHeaders();
      const response = await AXIOS.get(url, { headers });
      // laravel get-many request returns an array of models within response.data.data
      if (isArray(response.data)) return s.ROOT?.LOCALDB.addMany<T>(modelTypeName, response.data);
      // if the data shape is not an array, we don't know how to respond. return the response directly.
      console.warn(`Expected request to ${url} to return an array, but received type of [${typeof response.data}]. The response data has been returned directly.`);
      console.log(response.data);
      return response.data;
    },
    getRaw: async <T>(url: string) => {
      const headers = makeHeaders();
      const response = await AXIOS.get(url, { headers });
      return response as AxiosResponse<T>;
    },
    reset: () => {
      // currently does nothing
    },
    init: action((root: RootController) => {
      s.ROOT = root;
    })
  });

  return s;

}

export type APIController = ReturnType<typeof makeAPIController>;

