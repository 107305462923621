import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useParams } from 'react-router-dom';
import GlobalNoScroll from '../../components/GlobalNoScroll/GlobalNoScroll';
import SingleReportContent from '../../components/SingleReportContent/SingleReportContent';
import SingleReportFooter from '../../components/SingleReportFooter/SingleReportFooter';
import SingleReportHeader from '../../components/SingleReportHeader/SingleReportHeader';
import WindowTitle from '../../components/WindowTitle/WindowTitle';
import { useRootContext } from '../../controllers/root/root.controller';
import { useStore } from '../../utils/mobx.utils';
import './PageReportSingle.scss';

export type PageReportSingleProps = {
}

const PageReportSingle: React.FC<PageReportSingleProps> = props => {

  const { reportId } = useParams<{ reportId: string }>();
  const { REPORTS, LOCALDB, NAVIGATOR } = useRootContext();

  const s = useStore(() => ({
    get report() {
      return LOCALDB.data.reports.find(r => r.$.id && (+r.$.id === +reportId));
    },
    dismiss: () => {
      NAVIGATOR.redirectTo('/reports');
    },
  }))


  return <Observer children={() => (

    <div className="PageReportSingleLayer">
      <WindowTitle title="View a Report" />
      <GlobalNoScroll />
      <div className="PageReportBackdrop" onClick={s.dismiss} />
      <div className="PageReportSingle">
        {!REPORTS.ready && <div className="PageReportNotFound">
            <span>Loading...</span>
          </div>}
        {s.report && REPORTS.ready? <>
            <SingleReportHeader report={s.report} />
            <SingleReportContent report={s.report} />
            <SingleReportFooter report={s.report} />
          </> : <div className="PageReportNotFound">
            <span>Report not found.</span>
          </div>
        }
      </div>
    </div>

  )} />

}

export default PageReportSingle;