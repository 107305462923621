import { Observer } from 'mobx-react-lite';
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { useProps, useStore } from '../../utils/mobx.utils';
import './SanitizedHTMLContainer.scss';

type SanitizedHTMLContainerProps = {
  source: string | undefined,
}


const SanitizedHTMLContainer: React.FC<SanitizedHTMLContainerProps> = props => {
  const p = useProps(props);

const s = useStore(() => ({
  get sanitizedDescriptionHtml() {
    return sanitizeHtml(p.source ?? '');
  },
}));
  return <Observer children={() => (
    <div 
      dangerouslySetInnerHTML={{
        __html: s.sanitizedDescriptionHtml,
      }}
    />  
  )} />
}

export default SanitizedHTMLContainer;