import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useControllers } from '../../hooks/useRootController.hook';
import { Report } from '../../models/makeReport.model';
import joinClassName from '../../utils/className.utils';
import { reportError } from '../../utils/errors.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseButton from '../BaseButton/BaseButton';
import IconDownload from '../IconDownload/IconDownload';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import SearchDownloadIcon from '../SearchDownloadIcon/SearchDownloadIcon';
import "./ReportDownloader.scss";

export enum ReportAccessibleIconType {
  allow = "allow",
  disallow = "disallow",
}

export enum DownloaderViewType {
  SEARCH_LIST_ENTRY = "SEARCH_LIST_ENTRY",
  SINGLE_REPORT_HEADER = "SINGLE_REPORT_HEADER",
  REPORT_ENTRY = "REPORT_ENTRY",
}

type DownloaderViewTypeMap = {
  [key in DownloaderViewType]: any
}

type ReportDownloaderProps = {
  report: Report,
  downloaderViewType: keyof DownloaderViewTypeMap,
}

const ReportDownloader: React.FC<ReportDownloaderProps> = props => {

  const { UI, NAVIGATOR, LOCALDB, REPORTS } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    isDownloading: false,
    get categories() {
      return LOCALDB.data.categories;
    },
    downloadPDF: action(async (e?: React.MouseEvent) => {
      e?.preventDefault();
      e?.stopPropagation();

      if (!s.canAccess) {
        NAVIGATOR.redirectTo(`/reports/${p.report.$.id}`);
        UI.DIALOG.alert({
          heading: "Limited access with current plan",
          body: "Your current plan does not allow you to access this report. Please contact admin@carraighill.com for more information.",
        })
        return;
      }

      s.isDownloading = true
      try {
        let response : any = await REPORTS.getReportPDF(p.report);
        if(response === 500){
          UI.DIALOG.alert({
            heading: "Report Not Found",
            body: "The Report you are trying to download cannot be found.",
          })
        }
      } catch (e) {
        reportError(e);
      }
      s.isDownloading = false
    }),
    ignoreClickEvent: async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    },
    get canAccess() {
      return p.report.$.is_accessible;
    },
    get className() {
      return joinClassName("ReportDownloader", s.canAccess ? "" : "disallow")
    },
    get reportAccessibleIconType() {
      return s.canAccess ? ReportAccessibleIconType.allow : ReportAccessibleIconType.disallow
    },
  }));

  const DOWNLOADER_VIEW_TYPE: DownloaderViewTypeMap = {
    SEARCH_LIST_ENTRY: {
      renderable: <SearchDownloadIcon type={s.reportAccessibleIconType} />,
    },
    SINGLE_REPORT_HEADER: {
      renderable: <Observer children={() => (
        <BaseButton
          // className={joinClassName(!s.canAccess ? "disallow" : "")}
          onClick={(e) => s.downloadPDF(e)}
          title="Download Report"
          disabled={s.isDownloading}
        >
          {s.isDownloading ? <LoadingIndicator /> : <IconDownload type={s.reportAccessibleIconType} />}Download Report
        </BaseButton>
      )} />,
      renderDirectly: true,
    },
    REPORT_ENTRY: {
      renderable: <IconDownload type={s.reportAccessibleIconType} />
    },
  };

  return <Observer children={() => (
    <>
      {DOWNLOADER_VIEW_TYPE[p.downloaderViewType].renderDirectly
        ? <span className={s.className}>{DOWNLOADER_VIEW_TYPE[p.downloaderViewType].renderable}</span>
        : s.isDownloading
            ? (<span onClick={(evt) => s.ignoreClickEvent(evt)}>
                <LoadingIndicator />
              </span>)
            : (<span className={s.className} onClick={(evt) => s.downloadPDF(evt)}>
                {DOWNLOADER_VIEW_TYPE[p.downloaderViewType].renderable}
              </span>)
      }
    </>
  )} />
}

    export default ReportDownloader;