import download from "downloadjs";
import { action, observable, reaction } from "mobx";
import { Report } from "../../models/makeReport.model";
import { AXIOS } from "../../utils/axios.utils";
import { reportError } from "../../utils/errors.utils";
import { makeHeaders } from "./api.controller";
import { RootController } from "./root.controller";

export const makeReportsController = () => {
  const s = observable({
    ROOT: null as RootController | null,
    ready: false as boolean,
    get reports() {
      return s.ROOT?.LOCALDB.data.reports ?? [];
    },
    reset: () => {},
    getReportPDF: async (report: Report) => {
      const {id, title} = report.$;

      const headers = makeHeaders();
      try {
        const response = await AXIOS.get("api/download/report/" + id, {
          headers,
          responseType: "blob",
        })
        const content = response.headers["content-type"];
        download(response.data, `${!!title ? title : "Untitled"}.pdf`, content);
        return response
      } catch(e) {
        reportError(e);
        return e.response.status;
      }
    },
    getAllReports: async () => {
      await s.ROOT?.API.getMany("/api/reports", "reports");
      s.ready = true;
    },
    init: action((root: RootController) => {
      s.ROOT = root;
      reaction(
        () => s.ROOT?.AUTH.isAuthenticated,
        (isAuthenticated) => isAuthenticated && s.getAllReports(),
        { fireImmediately: true }
      );
    }),
  });
  return s;

}

export type ReportsController = ReturnType<typeof makeReportsController>;
